@use './abstracts/variables' as *;

.blocks-grid {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-basis: 100%;
  margin: 0;
  padding: 0;
}

article {
  align-items: flex-start !important;
  order: 2;

  @media screen and (min-width: $screen-md) {
    order: initial;
  }
}

.block {
  margin: 0;
  padding: 0;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  @media (min-width: $screen-md) {
    flex: 0 0 calc(50% - 5rem);
    margin: 0.75rem;
    padding: 1.5rem;
  }

  @media (min-width: $screen-xl) {
    flex: 0 0 calc(50% - 1.5rem);
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__heart {
    position: relative;
    top: 8rem;
    left: 20rem;
    transform: rotate(334deg);
  }

  &__image {
    min-width: 1px;
    min-height: 1px;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    overflow: hidden;

    @media screen and (min-width: $screen-sm) { // 600px
      padding-bottom: 70%;
    }

    @media screen and (min-width: $screen-md) { // 768px
      padding-bottom: 50%;
    }

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    @media screen and (min-width: $screen-md) {
      padding-bottom: 80%;
    }

    @media screen and (min-width: $screen-lg) {
      padding-bottom: 100%;
    }

    &-wrapper {
      position: relative;
      margin: 0;
      z-index: 2;
      min-width: 100%;
      min-height: auto;

      @media screen and (min-width: $screen-lg) {
        margin: 8rem 0 0;
        min-width: 50rem;
        min-height: 50rem;
      }
    }

    &-bg {
      display: none;
      position: absolute;
      height: 50%;
      width: 100%;
      z-index: 1;

      @media screen and (min-width: $screen-md) {
        display: block;
        height: 100%;
      }

      &--top-right {
        top: -6rem;
        right: 0rem;

        @media screen and (min-width: $screen-md) {
          top: -4rem;
          right: -4rem;
        }

        @media screen and (min-width: $screen-lg) {
          top: -6rem;
          right: -6rem;
        }
      }

      &--top-left {
        top: -6rem;
        left: 0rem;

        @media screen and (min-width: $screen-md) {
          top: -4rem;
          left: -4rem;
        }

        @media screen and (min-width: $screen-lg) {
          top: -6rem;
          left: -6rem;
        }
      }
    }
  }
}