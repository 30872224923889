@use './abstracts/variables' as *;

.partners {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: $font-family-oswald;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;

  h4 {
    font-size: 3.5rem;
    font-weight: normal;
    color: $text-black;
    text-align: center;

    @media screen and (min-width: $screen-sm) {
      font-size: 4rem;
    }

    @media screen and (min-width: $screen-md) {
      font-size: 5rem;
    }
  }

  &__logos {
    margin-top: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    @media screen and (min-width: $screen-sm) {
      margin-top: 7.5rem;
    }
  }

  &__logo {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $screen-sm) {
      margin: 0;
    }

    img {
      height: 8rem;
      max-width: 20rem;
      object-fit: contain;

      @media screen and (min-width: $screen-lg) {
        height: initial;
        max-width: initial;
      }
    }
  }
}