@use './abstracts/variables'as *;

body.animation {
  .hero {
    height: 100vh;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-name: hero-height-up;
    animation-timing-function: ease;

    &__image {
      transform: scale(0);
      animation-delay: 2s;
      animation-fill-mode: forwards;
      animation-duration: 1.2s;
      animation-iteration-count: 1;
      animation-name: hero-scale-up;
      animation-timing-function: cubic-bezier(0, .96, 1, 1.39);
    }

    h1 {
      transform: translateX(-250%);
      animation-delay: 1s;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-name: hero-slide-left;
      animation-timing-function: ease;
    }

    h2 {
      transform: translateX(-250%);
      animation-delay: 1s;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-name: hero-slide-left;
      animation-timing-function: ease;
    }
  }

  .navigation {
    &__socials {
      visibility: hidden;
      animation-delay: 4s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-name: navigation-show-socials;
      animation-timing-function: ease;
    }
  }
}

@keyframes navigation-show-socials {
  from {
    visibility: hidden;
  }

  to {
    visibility: visible;
  }
}

@keyframes hero-slide-left {
  from {
    transform: translateX(-250%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes hero-scale-up {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.2);
  }

  90% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hero-height-up {
  0% {
    height: 100vh;
  }

  100% {
    height: $hero-height;
  }
}

@media screen and (min-width: $screen-md) {
  @keyframes hero-height-up {
    0% {
      height: 100vh;
    }

    100% {
      height: $hero-height-md;
    }
  }
}