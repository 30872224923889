@use "./abstracts/variables" as *;

.footer {
  padding: 2rem 1.5rem;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-direction: column;

  &__copyright {
    width: 100%;
    text-align: center;
    color: $text-muted;
    font-size: 1.6rem;
    line-height: 1.5
  }

  &__socials {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
}
