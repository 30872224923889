/* Font families */
$font-family-oswald: 'Oswald', sans-serif;
$font-family-ubuntu: 'Ubuntu', sans-serif;

/* Base */
$atlas-blue: #B2C3DD;
$blue: #76A5BB;
$black: #3F3F3D;
$coralbisque: #F6CAAC;
$dark-grey: #abb1ba;
$green: #B5D6B2;
$grey: #C8C8C8;
$orange: #E9833D;
$peppermint: #D3E7D1;
$pink: #EBC3CB;
$primary: #D24D5A;
$red: #ad001d;
$white: #FFFFFF;

/* form */
$form-border: $grey;
$form-placeholder: $grey;
$form-error: $red;

/* border */
$border-orange: $coralbisque;

/* paragraphs */
$text-black: $black;
$text-green: $green;
$text-pink: $pink;
$text-white: $white;
$text-orange: $orange;
$text-muted: $dark-grey;

/* links */
$link: #ff526f;
$link-darkened: #ad001d;

/* Media Queries */
$screen-sm: 600px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1140px;
$screen-xxl: 1400px;

// Mobile devices: 320px — 480px
// iPads, Tablets: 481px — 768px
// Small screens, laptops: 769px — 1024px
// Desktops, large screens: 1025px — 1200px;
// Extra large screens, TV: 1201px
// Ultra large screens: 1440px

/* Backgrounds */
$bg-blue: $blue;
$bg-atlas-blue: $atlas-blue;
$bg-green: $green;
$bg-grey: $grey;
$bg-orange: $orange;
$bg-peppermint: $peppermint;
$bg-pink: $pink;
$bg-primary: $coralbisque;
$bg-white: $white;

/* SVG */
$fill-primary: $primary;

/* Default Height for the hero */
$hero-height: 40rem;
$hero-height-md: 55rem;