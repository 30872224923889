*, :before, :after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  color: #3f3f3d;
  margin: 0;
  font-family: Ubuntu, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  color: #ff526f;
  font-family: Ubuntu, sans-serif;
  text-decoration: none;
}

a:hover {
  color: #ad001d;
}

p {
  margin: 0 0 1.5rem;
  font-family: Ubuntu, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
}

ul {
  margin: 0 0 2rem;
}

ul li {
  margin: 0 0 .5rem;
  font-family: Ubuntu, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
}

.heart {
  opacity: .3;
  height: 100px;
}

.bg-peppermint {
  background-color: #d3e7d1;
}

.bg-green {
  background-color: #b5d6b2;
}

.bg-pink {
  background-color: #ebc3cb;
}

.bg-blue {
  background-color: #76a5bb;
}

.bg-atlas-blue {
  background-color: #b2c3dd;
}

.bg-primary {
  background-color: #f6caac;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-full {
  width: 100%;
  display: block;
}

.text-primary {
  color: #e9833d;
}

.text-green {
  color: #b5d6b2;
}

.text-pink {
  color: #ebc3cb;
}

.section {
  padding: 7rem 2rem 6rem;
}

@media screen and (min-width: 768px) {
  .section {
    padding: 10rem 0;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 12rem 0;
  }
}

@media screen and (min-width: 1140px) {
  .section {
    padding: 13rem 0;
  }
}

.section .title {
  font-family: Oswald, sans-serif;
  font-size: 2.4rem;
}

.section .highlight {
  font-family: Oswald, sans-serif;
  font-size: 2.2rem;
  font-weight: bold;
}

.container {
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 1024px;
  }
}

@media screen and (min-width: 1140px) {
  .container {
    width: 1140px;
  }
}

.blocks-grid {
  flex-wrap: wrap;
  flex: 1 0 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

article {
  order: 2;
  align-items: flex-start !important;
}

@media screen and (min-width: 768px) {
  article {
    order: initial;
  }
}

.block {
  flex-direction: column;
  flex: 0 0 100%;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .block {
    flex: 0 0 calc(50% - 5rem);
    margin: .75rem;
    padding: 1.5rem;
  }
}

@media (min-width: 1140px) {
  .block {
    flex: 0 0 calc(50% - 1.5rem);
  }
}

.block__center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.block__heart {
  position: relative;
  top: 8rem;
  left: 20rem;
  transform: rotate(334deg);
}

.block__image {
  min-width: 1px;
  min-height: 1px;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 600px) {
  .block__image {
    padding-bottom: 70%;
  }
}

@media screen and (min-width: 768px) {
  .block__image {
    padding-bottom: 50%;
  }
}

.block__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .block__image {
    padding-bottom: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .block__image {
    padding-bottom: 100%;
  }
}

.block__image-wrapper {
  z-index: 2;
  min-width: 100%;
  min-height: auto;
  margin: 0;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .block__image-wrapper {
    min-width: 50rem;
    min-height: 50rem;
    margin: 8rem 0 0;
  }
}

.block__image-bg {
  height: 50%;
  width: 100%;
  z-index: 1;
  display: none;
  position: absolute;
}

@media screen and (min-width: 768px) {
  .block__image-bg {
    height: 100%;
    display: block;
  }
}

.block__image-bg--top-right {
  top: -6rem;
  right: 0;
}

@media screen and (min-width: 768px) {
  .block__image-bg--top-right {
    top: -4rem;
    right: -4rem;
  }
}

@media screen and (min-width: 1024px) {
  .block__image-bg--top-right {
    top: -6rem;
    right: -6rem;
  }
}

.block__image-bg--top-left {
  top: -6rem;
  left: 0;
}

@media screen and (min-width: 768px) {
  .block__image-bg--top-left {
    top: -4rem;
    left: -4rem;
  }
}

@media screen and (min-width: 1024px) {
  .block__image-bg--top-left {
    top: -6rem;
    left: -6rem;
  }
}

.divider {
  padding: 0 1rem;
}

.divider hr {
  width: 100%;
  border: 0;
  border-top: 2px solid #c8c8c8;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .divider hr {
    width: 90%;
  }
}

.divider.only-on-mobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .divider.only-on-mobile {
    display: none;
  }
}

blockquote {
  color: #3f3f3d;
  z-index: 0;
  border-left: .5rem solid #e9833d;
  margin: 2rem 0;
  padding: 1.5rem 2rem 1.5rem 3rem;
  font-family: Oswald, sans-serif;
  font-size: 2.2rem;
  line-height: 1.4;
  position: relative;
}

.label {
  width: 100%;
  background-color: #e9833d;
  margin: 0 0 2.3rem;
  padding: 1rem;
  transform: translateX(0);
}

@media screen and (min-width: 768px) {
  .label {
    width: auto;
    padding: 0 1.5rem;
    transform: translateX(-1rem);
  }
}

@media screen and (min-width: 1024px) {
  .label {
    margin: 2rem 0 2.3rem;
  }
}

@media screen and (min-width: 1140px) {
  .label {
    padding: 0 3rem;
    transform: translateX(-3rem);
  }
}

.label h3 {
  color: #fff;
  margin: 0;
  font-family: Oswald, sans-serif;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .label h3 {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .label h3 {
    font-size: 4rem;
  }
}

.navigation__container {
  height: 5rem;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-top: 3.6rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .navigation__container {
    padding: 0 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .navigation__container {
    height: 11.9rem;
    justify-content: flex-end;
  }
}

.navigation__socials {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .navigation__socials {
    flex-grow: 0;
  }
}

.hero {
  height: 40rem;
  margin-top: 6rem;
  display: block;
  position: relative;
}

@media screen and (min-width: 768px) {
  .hero {
    height: 55rem;
  }
}

.hero__container {
  width: auto;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1400px) {
  .hero__container {
    max-width: 1400px;
    margin: 0 auto;
    display: block;
  }
}

.hero__text {
  text-align: center;
  order: 2;
  display: block;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .hero__text {
    order: 1;
    position: absolute;
    top: 11rem;
    left: 5rem;
  }
}

@media screen and (min-width: 1140px) {
  .hero__text {
    order: 1;
    top: 12rem;
    left: 5rem;
  }
}

.hero__image {
  width: 30rem;
  order: 1;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .hero__image {
    width: 40rem;
    position: absolute;
    top: 0;
    right: 3rem;
  }
}

@media screen and (min-width: calc(1024px + 5rem)) {
  .hero__image {
    width: 45rem;
    position: absolute;
    top: 0;
    right: 5rem;
  }
}

@media screen and (min-width: 1140px) {
  .hero__image {
    width: 45rem;
    position: absolute;
    top: 0;
    right: 5rem;
  }
}

@media screen and (min-width: 1400px) {
  .hero__image {
    width: 50rem;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.hero h1 {
  color: #3f3f3d;
  margin: 0 0 2rem;
  padding: 3rem 0 0;
  font-family: Oswald, sans-serif;
  font-size: 4rem;
  font-weight: normal;
  line-height: 1;
}

@media screen and (min-width: 600px) {
  .hero h1 {
    font-size: 6rem;
  }
}

@media screen and (min-width: 1024px) {
  .hero h1 {
    margin: 0;
    padding: 0;
    font-size: 8rem;
  }
}

@media screen and (min-width: 1140px) {
  .hero h1 {
    font-size: 9rem;
  }
}

.hero h2 {
  color: #3f3f3d;
  margin: 0;
  font-size: 3rem;
  font-weight: normal;
  line-height: 1;
}

@media screen and (min-width: 600px) {
  .hero h2 {
    margin: .5rem 0 0;
    font-size: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .hero h2 {
    margin: 1rem 0 0;
    font-size: 5rem;
  }
}

@media screen and (min-width: 1140px) {
  .hero h2 {
    margin: 1rem 0 0;
    font-size: 6rem;
  }
}

.partners {
  flex-direction: column;
  flex: 1 0 100%;
  justify-content: center;
  font-family: Oswald, sans-serif;
  display: flex;
}

.partners h4 {
  color: #3f3f3d;
  text-align: center;
  font-size: 3.5rem;
  font-weight: normal;
}

@media screen and (min-width: 600px) {
  .partners h4 {
    font-size: 4rem;
  }
}

@media screen and (min-width: 768px) {
  .partners h4 {
    font-size: 5rem;
  }
}

.partners__logos {
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 3.5rem;
  display: flex;
}

@media screen and (min-width: 600px) {
  .partners__logos {
    margin-top: 7.5rem;
  }
}

.partners__logo {
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  display: flex;
}

@media screen and (min-width: 600px) {
  .partners__logo {
    margin: 0;
  }
}

.partners__logo img {
  height: 8rem;
  max-width: 20rem;
  object-fit: contain;
}

@media screen and (min-width: 1024px) {
  .partners__logo img {
    height: initial;
    max-width: initial;
  }
}

.contact__information {
  align-items: flex-start !important;
}

.contact__text {
  margin-top: 1rem;
}

.contact__items {
  margin-top: 3rem;
}

.contact__items p {
  margin: 0;
  font-family: Ubuntu, sans-serif;
}

.contact__teaser {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  display: flex;
}

.contact__teaser p {
  font-family: Oswald, sans-serif;
  font-size: 2.5rem;
}

@media screen and (min-width: 1024px) {
  .contact__teaser p {
    font-size: 3rem;
  }
}

.contact__form {
  width: 100%;
}

.contact__form-group {
  margin-bottom: 3.7rem;
  display: block;
  position: relative;
}

.contact__form-group label {
  margin-bottom: .6rem;
  font-size: 1.6rem;
  display: block;
}

.contact__form-group input {
  height: 5rem;
  width: 100%;
  border: 1px solid #c8c8c8;
  padding: 1.2rem;
  font-family: Ubuntu, sans-serif;
}

.contact__form-group input:focus {
  border: 2px solid #f6caac;
  box-shadow: 0 0 10px #c8c8c8;
  outline: none !important;
}

.contact__form-group input::-ms-input-placeholder {
  color: #c8c8c8;
  font-size: 1.6rem;
}

.contact__form-group input::placeholder {
  color: #c8c8c8;
  font-size: 1.6rem;
}

.contact__form-group input.has-error ~ .error {
  display: block;
}

.contact__form-group textarea {
  height: 20rem;
  width: 100%;
  resize: none;
  border: 1px solid #c8c8c8;
  padding: 1.2rem;
  font-family: Ubuntu, sans-serif;
}

.contact__form-group textarea:focus {
  border: 2px solid #f6caac;
  box-shadow: 0 0 10px #c8c8c8;
  outline: none !important;
}

.contact__form-group textarea::-ms-input-placeholder {
  color: #c8c8c8;
  font-size: 1.6rem;
}

.contact__form-group textarea::placeholder {
  color: #c8c8c8;
  font-size: 1.6rem;
}

.contact__form-group textarea.has-error ~ .error {
  display: block;
}

.contact__form-group.has-error input, .contact__form-group.has-error textarea {
  border-color: #ad001d;
}

.contact__form--loading .contact__modal {
  display: flex;
}

.contact__form--loading .contact__form-group input, .contact__form--loading .contact__form-group textarea {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: rgba(233, 131, 61, .3);
}

.contact__form-error {
  color: #ad001d;
  font-size: 1.2rem;
  display: block;
  position: absolute;
  bottom: -2.5rem;
  left: 0;
}

.contact__submit {
  color: #e9833d;
  background-color: #fff;
  border: 1px solid #e9833d;
  padding: .75rem 2.5rem;
  font-size: 2rem;
  display: block;
}

.contact__submit:hover, .contact__submit.active {
  color: #fff;
  cursor: pointer;
  background-color: #e9833d;
  box-shadow: 0 0 10px #c8c8c8;
}

.contact__modal {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.contact__modal-inner {
  height: 250px;
  width: 250px;
  background-color: #fff;
  border: 1px solid #f6caac;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact__modal-inner svg {
  fill: #d24d5a;
  width: 65px;
  height: 70px;
}

.contact__modal-inner div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

.contact__modal-inner p {
  color: #3f3f3d;
  margin: 0 0 1rem;
  font-size: 1.6rem;
}

.contact__modal--success svg {
  fill: #b5d6b2;
}

.contact__modal--failure svg {
  fill: #ad001d;
}

.contact .label {
  margin-top: 0;
}

.socials {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.socials-item {
  margin-right: 20px;
}

.socials-item > svg {
  fill: #d24d5a;
  opacity: .5;
}

.socials-item > svg:hover {
  opacity: 1;
  cursor: pointer;
}

.socials-item:last-child {
  margin-right: 0;
}

.footer {
  flex-flow: column wrap;
  flex-grow: 1;
  padding: 2rem 1.5rem;
  display: flex;
}

.footer__copyright {
  width: 100%;
  text-align: center;
  color: #abb1ba;
  font-size: 1.6rem;
  line-height: 1.5;
}

.footer__socials {
  justify-content: center;
  margin-bottom: 1.5rem;
  display: flex;
}

.introduction {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 600px) {
  .introduction {
    padding-top: inherit;
    padding-bottom: inherit;
  }
}

.introduction h3 {
  margin: 0 0 3rem;
  padding: 0;
  font-family: Oswald, sans-serif;
  font-size: 2rem;
}

@media screen and (min-width: 768px) {
  .introduction h3 {
    font-size: 3rem;
  }
}

.introduction .block {
  min-height: auto;
  flex: 0 0 100%;
  padding: 0;
}

@media screen and (min-width: 600px) {
  .introduction .block {
    min-height: 50rem;
    margin: 0;
    padding: 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .introduction .block {
    min-height: 40rem;
  }
}

.testimonials {
  margin: 8rem 0 0;
}

.testimonials__controls {
  flex-grow: 1;
  justify-content: flex-end;
  gap: 2rem;
  margin: 0;
  display: flex;
  position: relative;
}

.testimonials__control svg {
  height: 3.2rem;
  width: 3.2rem;
}

.testimonials__control:hover {
  cursor: pointer;
}

.testimonials__control:hover svg {
  fill: #e9833d;
}

.testimonials__content {
  margin-top: 4rem;
  position: relative;
}

.testimonials__content .swiper {
  padding: 0 5rem;
}

.card {
  width: 55rem;
  max-width: 55rem;
  height: auto;
  background-color: #fff;
  padding: 4rem;
  box-shadow: 0 0 1.5rem #c8c8c8;
}

.card:hover {
  cursor: pointer;
}

.card__heading {
  gap: 4rem;
  margin-bottom: 2rem;
  display: flex;
}

.card__image {
  height: 7rem;
  width: auto;
}

.card__image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.card__name {
  margin-bottom: .5rem;
  font-family: Oswald, sans-serif;
  font-size: 3rem;
}

.card__company {
  margin: 0;
  font-family: Ubuntu, sans-serif;
  font-size: 1.2rem;
}

.card__content p {
  font-family: Ubuntu, sans-serif;
}

body.animation .hero {
  height: 100vh;
  animation-name: hero-height-up;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

body.animation .hero__image {
  animation-name: hero-scale-up;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier(0, .96, 1, 1.39);
  animation-iteration-count: 1;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  transform: scale(0);
}

body.animation .hero h1, body.animation .hero h2 {
  animation-name: hero-slide-left;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transform: translateX(-250%);
}

body.animation .navigation__socials {
  visibility: hidden;
  animation-name: navigation-show-socials;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: 4s;
  animation-fill-mode: forwards;
}

@keyframes navigation-show-socials {
  from {
    visibility: hidden;
  }

  to {
    visibility: visible;
  }
}

@keyframes hero-slide-left {
  from {
    transform: translateX(-250%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes hero-scale-up {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.2);
  }

  90% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hero-height-up {
  0% {
    height: 100vh;
  }

  100% {
    height: 40rem;
  }
}

@media screen and (min-width: 768px) {
  @keyframes hero-height-up {
    0% {
      height: 100vh;
    }

    100% {
      height: 55rem;
    }
  }
}

/*# sourceMappingURL=index.ef276109.css.map */
