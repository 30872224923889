@use './abstracts/variables' as *;

.contact {
  &__information {
    align-items: flex-start !important;
  }

  &__text {
    margin-top: 1rem;
  }

  &__items {
    margin-top: 3rem;

    p {
      font-family: $font-family-ubuntu;
      margin: 0;
    }
  }

  &__teaser {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-family: $font-family-oswald;
      font-size: 2.5rem;

      @media screen and (min-width: $screen-lg) {
        font-size: 3rem;
      }
    }
  }

  &__form {
    width: 100%;

    &-group {
      position: relative;
      display: block;
      margin-bottom: 3.7rem;

      label {
        display: block;
        font-size: 1.6rem;
        margin-bottom: 0.6rem;
      }

      input {
        height: 5rem;
        width: 100%;
        border: 1px solid $form-border;
        font-family: $font-family-ubuntu;
        padding: 1.2rem;

        &:focus {
          outline: none !important;
          border: 2px solid $border-orange;
          box-shadow: 0 0 10px $grey;
        }

        &::placeholder {
          color: $form-placeholder;
          font-size: 1.6rem;
        }

        &.has-error {
          & ~.error {
            display: block;
          }
        }
      }

      textarea {
        height: 20rem;
        width: 100%;
        border: 1px solid $form-border;
        font-family: $font-family-ubuntu;
        padding: 1.2rem;
        resize: none;

        &:focus {
          outline: none !important;
          border: 2px solid $border-orange;
          box-shadow: 0 0 10px $grey;
        }

        &::placeholder {
          color: $form-placeholder;
          font-size: 1.6rem;
        }

        &.has-error {
          & ~ .error {
            display: block;
          }
        }
      }

      &.has-error {
        input,
        textarea {
          border-color: $form-error;
        }
      }
    }

    &--loading {
      .contact__modal {
        display: flex;
      }

      .contact__form-group {
        input,
        textarea {
          pointer-events: none;
          user-select: none;
          background-color: rgba($bg-orange, .3);
        }
      }
    }

    &-error {
      display: block;
      position: absolute;
      bottom: -2.5rem;
      left: 0;
      font-size: 1.2rem;
      color: $form-error;
    }
  }

  &__submit {
    display: block;
    padding: 0.75rem 2.5rem;
    font-size: 2rem;
    color: $text-orange;
    background-color: $bg-white;
    border: 1px solid $orange;

    &:hover,
    &.active {
      color: $text-white;
      background-color: $bg-orange;
      box-shadow: 0 0 10px $grey;
      cursor: pointer;
    }
  }

  &__modal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;

    &-inner {
      background-color: $bg-white;
      height: 250px;
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-orange;

      svg {
        fill: $primary;
        width: 65px;
        height: 70px;
      }

      div {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      p {
        margin: 0 0 1rem;
        font-size: 1.6rem;
        color: $text-black;
      }
    }

    &--success {
      svg {
        fill: $green;
      }
    }

    &--failure {
      svg {
        fill: $red;
      }
    }
  }

  & .label {
    margin-top: 0;
  }
}