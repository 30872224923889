@use './abstracts/variables' as *;

.introduction {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media screen and (min-width: $screen-sm) {
    padding-top: inherit;
    padding-bottom: inherit;
  }

  h3 {
    margin: 0 0 3rem;
    padding: 0;
    font-family: $font-family-oswald;
    font-size: 2rem;

    @media screen and (min-width: $screen-md) {
      font-size: 3rem;
    }
  }

  .block {
    padding: 0;
    min-height: auto;
    flex: 0 0 100%;

    @media screen and (min-width: $screen-sm) {
      margin: 0;
      padding: 5rem;
      min-height: 50rem;
    }

    @media screen and (min-width: $screen-lg) {
      min-height: 40rem;
    }
  }
}