@use './abstracts/variables' as *;

.container {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;

  // @media screen and (min-width: $screen-sm) {
  //   width: $screen-sm;
  // }

  @media screen and (min-width: $screen-md) {
    width: $screen-md;
  }

  @media screen and (min-width: $screen-lg) {
    width: $screen-lg;
  }

  @media screen and (min-width: $screen-xl) {
    width: $screen-xl;
  }
}
