@use './abstracts//variables' as *;

blockquote {
  position: relative;
  margin: 2rem 0;
  padding: 1.5rem 2rem 1.5rem 3rem;
  font-family: 'Oswald', sans-serif;
  font-size: 2.2rem;
  line-height: 1.4;
  border-left: .5rem solid $bg-orange;
  color: $text-black;
  z-index: 0;
}