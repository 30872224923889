@use "./abstracts/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  color: $text-black;
  font-family: $font-family-ubuntu;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: $link;
  text-decoration: none;
  font-family: $font-family-ubuntu;

  &:hover {
    color: $link-darkened;
  }
}

p {
  margin: 0 0 1.5rem;
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: $font-family-ubuntu;
}

ul {
  margin: 0 0 2rem;
}

ul li {
  margin: 0 0 .5rem;
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: $font-family-ubuntu;
}

.heart {
  opacity: .3;
  height: 100px;
}

.bg {
  &-peppermint {
    background-color: $bg-peppermint;
  }

  &-green {
    background-color: $bg-green;
  }

  &-pink {
    background-color: $bg-pink;
  }

  &-blue {
    background-color: $bg-blue;
  }

  &-atlas-blue {
    background-color: $bg-atlas-blue;
  }

  &-primary {
    background-color: $bg-primary;
  }
}

.text {
  &-bold {
    font-weight: bold;
  }

  &-center {
    text-align: center;
  }

  &-full {
    display: block;
    width: 100%;
  }

  &-primary {
    color: $text-orange;
  }

  &-green {
    color: $text-green;
  }

  &-pink {
    color: $text-pink;
  }
}