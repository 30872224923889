@use './abstracts/variables' as *;

.testimonials {
  margin: 8rem 0 0;

  &__controls {
    margin: 0;
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 2rem;
  }

  &__control {
    svg {
      height: 3.2rem;
      width: 3.2rem;
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: $orange;
      }
    }
  }

  &__content {
    position: relative;
    margin-top: 4rem;

    .swiper {
      padding: 0 5rem;
    }
  }
}