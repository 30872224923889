@use "./styles/base";

@use "./styles/section";
@use "./styles/container";
@use "./styles/block";
@use "./styles/divider";
@use "./styles/quote";
@use "./styles/label";
@use "./styles/navigation";
@use "./styles/hero";
@use "./styles/partners";
@use "./styles/contact";
@use "./styles/socials";
@use "./styles/footer";
@use "./styles/introduction";
@use "./styles/testimonials";
@use "./styles/card";

@use "./styles/animation";