@use './abstracts/variables' as *;

.card {
  background-color: $white;
  padding: 4rem;
  box-shadow: 0 0 1.5rem $grey;
  width: 55rem;
  max-width: 55rem;
  height: auto;

  &:hover {
    cursor: pointer;
  }

  &__heading {
    display: flex;
    gap: 4rem;
    margin-bottom: 2rem;
  }

  &__image {
    height: 7rem;
    width: auto;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__name {
    margin-bottom: .5rem;
    font-family: $font-family-oswald;
    font-size: 3rem;
  }

  &__company {
    margin: 0;
    font-family: $font-family-ubuntu;
    font-size: 1.2rem;
  }

  &__content {
    // word-break: break-all;

    p {
      font-family: $font-family-ubuntu;
    }
  }
}