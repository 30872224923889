@use './abstracts/variables' as *;

.divider {
  padding: 0 1rem;

  hr {
    margin: 0 auto;
    width: 100%;
    border: 0;
    border-top: 2px solid $grey;

    @media screen and (min-width: $screen-md) {
      width: 90%;
    }
  }

  &.only-on-mobile {
    display: block;

    @media screen and (min-width: $screen-md) {
      display: none;
    }
  }
}