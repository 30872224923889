@use "./abstracts/variables" as *;

.navigation {
  &__container {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    height: 5rem;
    margin-top: 3.6rem;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $screen-md) {
      padding: 0 5rem 0;
    }

    @media screen and (min-width: $screen-lg) {
      height: 11.9rem;
      justify-content: flex-end;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    @media screen and (min-width: $screen-md) {
      flex-grow: 0;
    }
  }
}