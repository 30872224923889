@use './abstracts/variables' as *;

.hero {
  position: relative;
  display: block;
  margin-top: 6rem;
  height: $hero-height;

  @media screen and (min-width: $screen-md) {
    height: $hero-height-md;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;

    @media screen and (min-width: $screen-xxl) {
      display: block;
      margin: 0 auto;
      max-width: $screen-xxl;
    }
  }

  &__text {
    position: relative;
    display: block;
    text-align: center;
    order: 2;

    @media screen and (min-width: $screen-lg) {
      position: absolute;
      top: 11rem;
      left: 5rem;
      order: 1;
    }

    @media screen and (min-width: $screen-xl) {
      top: 12rem;
      left: 5rem;
      order: 1;
    }
  }

  &__image {
    position: relative;
    width: 30rem;
    order: 1;

    @media screen and (min-width: $screen-lg) {
      position: absolute;
      top: 0;
      right: 3rem;
      width: 40rem;
    }

    @media screen and (min-width: calc($screen-lg + 5rem)) {
      position: absolute;
      top: 0;
      right: 5rem;
      width: 45rem;
    }

    @media screen and (min-width: $screen-xl) {
      position: absolute;
      top: 0;
      right: 5rem;
      width: 45rem;
    }

    @media screen and (min-width: $screen-xxl) {
      position: absolute;
      top: 0;
      right: 0rem;
      width: 50rem;
    }
  }

  h1 {
    margin: 0 0 2rem;
    padding: 3rem 0 0;
    font-family: $font-family-oswald;
    font-size: 4rem;
    color: $text-black;
    line-height: 1;
    font-weight: normal;

    @media screen and (min-width: $screen-sm) {
      font-size: 6rem;
    }

    @media screen and (min-width: $screen-lg) {
      margin: 0;
      padding: 0;
      font-size: 8rem;
    }

    @media screen and (min-width: $screen-xl) {
      font-size: 9rem;
    }
  }

  h2 {
    margin: 0;
    font-size: 3rem;
    color: $text-black;
    line-height: 1;
    font-weight: normal;

    @media screen and (min-width: $screen-sm) {
      margin: 0.5rem 0 0;
      font-size: 3rem;
    }

    @media screen and (min-width: $screen-lg) {
      margin: 1rem 0 0;
      font-size: 5rem;
    }

    @media screen and (min-width: $screen-xl) {
      margin: 1rem 0 0;
      font-size: 6rem;
    }
  }
}