@use './abstracts/variables' as *;

.socials {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-item {
    margin-right: 20px;

    &>svg {
      fill: $fill-primary;
      opacity: .5;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}