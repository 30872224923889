@use "./abstracts/variables" as *;

.section {
  padding: 7rem 2rem 6rem;

  @media screen and (min-width: $screen-md) {
    padding: 7rem 1rem;
  }

  @media screen and (min-width: $screen-md) {
    padding: 10rem 0rem;
  }

  @media screen and (min-width: $screen-lg) {
    padding: 12rem 0;
  }

  @media screen and (min-width: $screen-xl) {
    padding: 13rem 0;
  }

  .title {
    font-family: 'Oswald', sans-serif;
    font-size: 2.4rem;
  }

  .highlight {
    font-family: 'Oswald', sans-serif;
    font-size: 2.2rem;
    font-weight: bold;
  }
}
