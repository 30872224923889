@use "./abstracts/variables" as *;

.label {
  margin: 0 0 2.3rem;
  padding: 1rem;
  width: 100%;
  background-color: $bg-orange;
  transform: translateX(0px);

  @media screen and (min-width: $screen-md) {
    padding: 0 1.5rem;
    width: auto;
    transform: translateX(-1rem);
  }

  @media screen and (min-width: $screen-lg) {
    margin: 2rem 0 2.3rem;
  }

  @media screen and (min-width: $screen-xl) {
    padding: 0 3rem;
    transform: translateX(-3rem);
  }

  h3 {
    margin: 0;
    font-family: 'Oswald', sans-serif;
    font-size: 2.2rem;
    color: $text-white;
    line-height: 1.5;
    font-weight: bold;

    @media screen and (min-width: $screen-md) {
      font-size: 3rem;
    }

    @media screen and (min-width: $screen-lg) {
      font-size: 4rem;
    }
  }
}